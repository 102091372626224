* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

p {
    font-size: 1.0rem;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.2rem;
}

h5 {
    font-size: 1rem;
}
